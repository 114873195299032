<template>
  <div>
    <div class="row">
      <div class="col-6">
        <h1 class="card-label">
          Relevance Tuning
          <span class="d-block text-muted pt-2 font-size-lg"
            >Set field weights and boosts.</span
          >
        </h1>
        <v-switch v-model="advanced" label="Advanced"></v-switch>
      </div>
      <div class="col-6 ">
        <!--begin::Button-->
        <v-flex class="ml-2 float-right ">
          <collection_select />
        </v-flex>
        <v-btn
          :loading="loading"
          @click.prevent="save"
          color="primary"
          href="#"
          class="btn btn-primary font-weight-bolder float-right"
        >
          <span class="svg-icon svg-icon-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="9" cy="15" r="6"></circle>
                <path
                  d="M8.8012943,7.00241953 C9.83837775,5.20768121
														11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10
														C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057
														C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722
														13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,
														7.00241953 Z"
                  fill="#000000"
                  opacity="0.3"
                ></path>
              </g>
            </svg>
            <!--end::Svg Icon--> </span
          >Save</v-btn
        >
        <a
          @click.prevent="reloadData"
          href="#"
          class="btn btn-text-danger btn-hover-light-danger font-weight-bold float-right mr-2"
        >
          <span class="svg-icon svg-icon-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="9" cy="15" r="6"></circle>
                <path
                  d="M8.8012943,7.00241953 C9.83837775,5.20768121
														11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10
														C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057
														C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722
														13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,
														7.00241953 Z"
                  fill="#000000"
                  opacity="0.3"
                ></path>
              </g>
            </svg>
            <!--end::Svg Icon--> </span
          >Restore Defaults</a
        >
        <!--end::Button-->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <h2>Manage fields</h2>
        <form class="d-flex align-items-center mb-9 bg-light rounded p-5">
          <div class="form-group row">
            <div class="col-9">
              <input
                class="form-control"
                type="text"
                v-bind:placeholder="'Filter ' + fields.length + ' fields...'"
                v-model="filterFields"
              />
            </div>
            <div class="col-3">
              <input
                type="submit"
                value="Search"
                class="btn btn-light-primary font-weight-bolder mr-2"
                @click.prevent="search"
              />
            </div>
          </div>
        </form>
        <div role="tablist">
          <div v-for="field in filteredList" :key="field.field_name">
            <b-card
              no-body
              class="mb-1"
              v-if="(enableBoost && field.boostable) || advanced"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <div
                  class="d-flex align-items-center mb-9 bg-light rounded p-5"
                  v-b-toggle="'accordion-' + field.field_name"
                  variant="info"
                  @click.prevent=""
                >
                  <!--begin::Icon-->
                  <span class="svg-icon svg-icon-warning mr-5">
                    <span class="svg-icon svg-icon-lg"> </span>
                  </span>
                  <!--end::Icon-->
                  <!--begin::Title-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <a
                      href="#"
                      class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                      >{{ field.field_name }}</a
                    >
                    <span class="text-muted font-weight-bold ">{{
                      field.type
                    }}</span>
                  </div>
                  <!--end::Title-->
                  <!--begin::Lable-->
                  <span
                    v-if="field.user_phrase_check && advanced"
                    class="label-light-primary label label-lg  mr-2  label-inline font-weight-bold py-4"
                    ><span class="fas fas fa-user-tag"></span
                  ></span>

                  <span
                    v-if="
                      field.wildcard_enable && field.wildcard > 0 && advanced
                    "
                    class="label label-light-primary label-lg  mr-2  label-inline font-weight-bold py-4"
                    ><span class="fas fa-caret-right mr-2"></span
                    >{{ field.wildcard_enable ? field.wildcard : "-" }}</span
                  >
                  <span
                    v-if="
                      field.phrase_enable && field.phrase_weight > 0 && advanced
                    "
                    class="label label-light-primary label-lg  mr-2  label-inline font-weight-bold py-4"
                    ><span class="fas fa-barcode mr-2"></span
                    >{{ field.phrase_enable ? field.phrase_weight : "-" }}</span
                  >
                  <span
                    v-if="field.boosts.length > 0"
                    class="label label-light-primary label-lg  mr-2  label-inline font-weight-bold py-4"
                    ><span class="fas fa-angle-double-up mr-2"></span
                    >{{ field.boosts.length }}</span
                  >
                  <span
                    v-if="advanced"
                    v-bind:class="[
                      field.searchable && field.weight > 0
                        ? 'label-light-primary'
                        : 'label-light'
                    ]"
                    class="label label-lg  mr-2  label-inline font-weight-bold py-4"
                    ><span class="fas fa-search mr-2"></span
                    >{{ field.searchable ? field.weight : "-" }}</span
                  >
                  <!--end::Lable-->
                </div>
              </b-card-header>
              <b-collapse
                v-bind:id="'accordion-' + field.field_name"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body v-if="advanced">
                  <b-card-sub-title>User Phrase Check</b-card-sub-title>
                  <v-switch
                    v-model="field.user_phrase_check"
                    :disabled="field.type !== 'keyword' && field.type !== 'api_keyword_localize'"
                    @click.prevent="search"
                    :label="
                      field.type === 'keyword' || field.type === 'api_keyword_localize'
                        ? 'Search in this field'
                        : 'This field can not be searchable'
                    "
                  ></v-switch>
                  <b-card-sub-title>Text Search</b-card-sub-title>
                  <v-switch
                    v-model="field.searchable"
                    :disabled="field.type !== 'keyword' && field.type !== 'api_keyword_localize'"
                    @click.prevent="search"
                    :label="
                      field.type === 'keyword' || field.type === 'api_keyword_localize'
                        ? 'Search in this field'
                        : 'This field can not be searchable'
                    "
                  ></v-switch>
                  <b-card-group v-if="field.searchable">
                    <hr />
                    <b-card-sub-title>Weight</b-card-sub-title>
                    <v-row>
                      <v-col cols="8">
                        <v-slider
                          v-model="field.weight"
                          color="orange"
                          min="0"
                          max="20"
                          step="0.1"
                          v-on:change="search"
                          thumb-label
                        ></v-slider>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="field.weight"
                          v-on:keyup="search"
                          single-line
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row>
                      <v-col cols="12">
                        <v-switch
                          v-model="field.fuzzy"
                          @click.prevent="search"
                          label="Make field fuzzy"
                        ></v-switch>
                        <p>
                          Returns documents that contain terms similar to the
                          search term, as measured by a Levenshtein edit
                          distance.
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-switch
                          v-model="field.shingle"
                          @click.prevent="search"
                          label="Make field Shingle"
                        ></v-switch>
                        <p>
                          Add shingles, or word n-grams, to a token stream by
                          concatenating adjacent tokens. For example, tokenizers
                          convert "SONY FE 50 mm" to [ SONY, FE, 50, mm ]. You
                          can use the shingle filter to add two-word shingles to
                          this stream: [ <strong>SONY</strong>, SONYFE,
                          SONYFE50, SONYFE50mm, <strong>FE</strong>, FE50,
                          FE50mm, <strong>50</strong>, 50mm,
                          <strong>mm</strong>].
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-switch
                          v-model="field.stemmer"
                          @click.prevent="search"
                          label="Make field Stemmer"
                        ></v-switch>
                        <p>
                          Provides algorithmic stemming for several languages,
                          some with additional variants. The following analyze
                          API request uses the stemmer filter’s default porter
                          stemming algorithm to stem the foxes jumping quickly
                          to the fox jump
                        </p>
                      </v-col>
                    </v-row>
                  </b-card-group>

                  <b-card-sub-title>Autocomplete Search</b-card-sub-title>
                  <v-switch
                    v-model="field.wildcard_enable"
                    :disabled="field.type !== 'keyword' && field.type !== 'api_keyword_localize'"
                    @click.prevent="search"
                    :label="
                      field.type === 'keyword' || field.type === 'api_keyword_localize'
                        ? 'Search in this field'
                        : 'This field can not be searchable'
                    "
                  ></v-switch>
                  <b-card-group v-if="field.wildcard_enable">
                    <hr />
                    <b-card-sub-title>Weight</b-card-sub-title>
                    <v-row>
                      <v-col cols="8">
                        <v-slider
                          v-model="field.wildcard"
                          color="orange"
                          min="0"
                          max="20"
                          step="0.1"
                          v-on:change="search"
                          thumb-label
                        ></v-slider>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="field.wildcard"
                          v-on:keyup="search"
                          single-line
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </b-card-group>

                  <b-card-sub-title>Phrase Search</b-card-sub-title>
                  <v-switch
                    v-model="field.phrase_enable"
                    :disabled="field.type !== 'text'"
                    @click.prevent="search"
                    :label="
                      field.type === 'text'
                        ? 'Search in this field'
                        : 'This field can not be searchable'
                    "
                  ></v-switch>

                  <b-card-group v-if="field.phrase_enable">
                    <hr />
                    <b-card-sub-title>Weight</b-card-sub-title>
                    <v-row>
                      <v-col cols="8">
                        <v-slider
                          v-model="field.phrase_weight"
                          color="orange"
                          min="0"
                          max="20"
                          step="0.1"
                          v-on:change="search"
                          thumb-label
                        ></v-slider>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="field.phrase_weight"
                          v-on:keyup="search"
                          single-line
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </b-card-group>

                  <v-container v-if="enableMandatory">
                    <b-card-sub-title>Mandatory field</b-card-sub-title>
                    <v-switch
                      v-model="field.mandatory"
                      :disabled="field.type !== 'boolean'"
                      @click.prevent="search"
                      :label="
                        field.type !== 'boolean'
                          ? 'This field can not be mandatory'
                          : 'Set this field as mandatory'
                      "
                    ></v-switch>
                  </v-container>
                </b-card-body>

                <b-card-body v-if="enableBoost && field.boostable">
                  <div>
                    <b-card-sub-title>Boosts</b-card-sub-title>
                    <b-dropdown text="Add boost">
                      <b-dropdown-item @click.prevent="prepareValue(field)"
                        >Value
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          field.type === 'float' || field.type === 'integer'
                        "
                        @click.prevent="addFunction(field)"
                        >Functional
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <v-container v-for="boost in field.boosts" :key="boost.name">
                    <v-row v-if="boost.type == 'value'">
                      <v-col cols="12">
                        <v-text-field
                          v-model="boost.value"
                          v-on:change="search"
                          disabled="true"
                          hint="Catalog Term"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="5">
                        <v-slider
                          v-model="boost.boost"
                          color="orange"
                          min="-10"
                          max="20"
                          step="0.1"
                          v-on:change="search"
                          thumb-label
                        ></v-slider>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="boost.boost"
                          v-on:keyup="search"
                          single-line
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          v-if="advanced"
                          v-model="boost.based"
                          v-on:change="search"
                          label="Query relevance"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="1">
                        <v-btn @click.prevent="removeValue(field, boost)"
                          >x
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-else-if="boost.type == 'functional'">
                      <v-col cols="6">
                        <v-select
                          v-model="boost.function"
                          :items="['Logarithmic', 'Exponential', 'Linear']"
                          filled
                          label="Function"
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="boost.operation"
                          :items="['Add', 'Multiply']"
                          filled
                          label="Operation"
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-slider
                          v-model="boost.boost"
                          color="orange"
                          min="-10"
                          max="20"
                          step="0.01"
                          v-on:change="search"
                          thumb-label
                        ></v-slider>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="boost.boost"
                          v-on:keydown="search"
                          single-line
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-btn @click.prevent="removeValue(field, boost)"
                          >x
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h2 class="card-label">
                Preview
              </h2>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <div class="col-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Search...'"
                  v-on:keyup="search"
                  v-model="searchString"
                />
              </div>
              <div class="col-4">Time took: {{ took_time }}s</div>
            </div>
            <div
              class="card-body"
              style="overflow: auto; width:100%;height: 80vh;"
            >
              <query_results :items="previewList" type="relevance_tuning" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <schema_autocomplete :schema="boost_schema_value" :callback="addValue" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  BoostFunction,
  PREVIEW_FIELDS,
  PULL_FIELDS,
  PUT_FIELDS
} from "@/core/services/store/relevance_tuning.module";
import { mapGetters, mapState } from "vuex";
import { Boosts } from "@/core/services/store/relevance_tuning.module";
import collection_select from "@/view/pages/collections/collection_select";
import { RELOAD_MESSAGE } from "@/core/services/store/message.module";
import query_results from "@/view/pages/query-tester/results";
import schema_autocomplete from "@/view/pages/relevance-tuning/schemaAutocomplete";
import Swal from "sweetalert2";

export default {
  components: { collection_select, query_results, schema_autocomplete },
  data() {
    return {
      enableBoost: true,
      enableMandatory: false,
      filterFields: "",
      filterBuffer: "",
      searchBuffer: "",
      searchString: "",
      boost_schema_value: null,
      advanced: false
    };
  },
  methods: {
    reloadData() {
      if (this.selectedCollection && this.selectedCollection.id > 0) {
        this.$store.dispatch(PULL_FIELDS, this.selectedCollection);
      }
    },
    addValue(field, value) {
      this.boost_schema_value = null;
      if (value === "") {
        return;
      }
      field.boosts.push(new Boosts(value, 0, false));
    },
    prepareValue(field) {
      this.boost_schema_value = field;
    },
    addFunction(field) {
      field.boosts.push(new BoostFunction("Logarithmic", "Multiply", 1));
    },
    removeValue(field, boost) {
      field.boosts.forEach(function(entry, index) {
        if (entry.value === boost.value) {
          field.boosts.splice(index, 1);
        }
      });
      this.search();
    },
    search() {
      this.$store.dispatch(PREVIEW_FIELDS, {
        collection_id: this.selectedCollection.id,
        fields: this.fields,
        q: this.searchString
      });
    },
    save() {
      Swal.fire({
        title: "Are you sure?",
        text: `By clicking on agree, this relevance tuning will be deployed on production. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, save it!"
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch(PUT_FIELDS, {
              fields: this.fields,
              collection_id: this.selectedCollection.id
            })
            .then(() => {
              Swal.fire(
                "Save!",
                `Your customers now exploration your items by new relevance tuning.`,
                "success"
              );
            });
        }
      });
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    }
  },
  computed: {
    changeCollection() {
      return this.selectedCollection.id;
    },
    changeProject() {
      return this.currentProject.token;
    },
    filteredList() {
      return this.fields.filter(item => {
        return item.field_name
          .toLowerCase()
          .includes(this.filterFields.toLowerCase());
      });
    },
    previewList() {
      return this.previews;
    },
    wildcardEnable() {
      return this.fields.wildcard_enable;
    },
    ...mapState({
      errors: state => state.relevanceTuning.errors,
      fields: state => state.relevanceTuning.fields,
      previews: state => state.relevanceTuning.previews,
      took_time: state => state.relevanceTuning.took_time,
      message: state => state.message.message,
      loading: state => state.relevanceTuning.loading,
      searchString: state => state.relevanceTuning.searchString
    }),
    ...mapGetters(["selectedCollection", "currentProject"])
  },
  beforeCreate() {
    this.$store.dispatch(RELOAD_MESSAGE);
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Relevance Tuning" }]);
  }
};
</script>
