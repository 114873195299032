<template>
  <table class="table" v-if="items">
    <thead>
      <tr>
        <th class="pl-0 font-weight-bold text-muted text-uppercase">
          {{ $t("query_tester.results.item") }}
        </th>
        <th class="text-left pl-0 font-weight-bold text-muted text-uppercase">
          {{ $t("query_tester.results.score") }}
        </th>
        <th
          class="text-left pl-0 font-weight-bold text-muted text-uppercase"
          v-if="selectedCollection.mapping.category_name"
        >
          {{ $t("query_tester.results.category") }}
        </th>
        <th
          class="text-left font-weight-bold text-muted text-uppercase"
          v-if="selectedCollection.mapping.brand_name"
        >
          {{ $t("query_tester.results.brand") }}
        </th>
        <th
          class="text-left font-weight-bold text-muted text-uppercase"
          v-if="selectedCollection.mapping.price_view"
        >
          {{ $t("query_tester.results.price") }}
        </th>
        <th class="text-left font-weight-bold text-muted text-uppercase">
          {{ $t("query_tester.results.relevant_variants_cnt") }}
        </th>
        <th
          class="text-right font-weight-bold text-muted text-uppercase"
          v-if="type === 'curation'"
        >
          {{ $t("query_tester.results.action") }}
        </th>
      </tr>
    </thead>
    <tbody v-for="item in items" :key="item._id">
      <tr @click="clickRow(item)">
        <td class="border-0 pl-0 pt-7 d-flex align-items-center">
          <!--begin::Symbol-->
          <div class="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
            <div
              v-if="selectedCollection.mapping.image_link"
              class="symbol-label"
              :style="{
                backgroundImage: 'url(' + item.image_link + ')'
              }"
            ></div>
          </div>
          <!--end::Symbol-->
          <p v-if="item.name_highlight" v-html="item.name_highlight"></p>
          <p v-else>{{ item[selectedCollection.schema_front] }}</p>
        </td>
        <td class="text-primary pr-0 pt-7 text-left align-middle">
          <div
            class="badge badge-warning mr-5"
            title="Skore před aplikovaním dodatečných boostu (Query relevance)"
          >
            {{ item.score > 10000000 ? "Promoted" : item.score }}
          </div>
          <div
            class="badge badge-success"
            title="Skore včetně aplikovaných dodatečných boostu (Function relevance)"
          >
            <span v-if="item.relevant">R</span>
            {{ item.score_final > 10000000 ? "Promoted" : item.score_final }}
          </div>
        </td>
        <td
          class="text-primary pr-0 pt-7 text-left align-middle"
          v-if="selectedCollection.mapping.category_name"
        >
          {{ item && item.category_name ? item.category_name : "" }}
        </td>
        <td
          class="text-primary pr-0 pt-7 text-left align-middle"
          v-if="selectedCollection.mapping.brand_name"
        >
          {{ item.brand_name }}
        </td>
        <td
          class="text-primary pr-0 pt-7 text-left align-middle"
          v-if="selectedCollection.mapping.price_view"
        >
          {{ item.price_view.view }}
        </td>
        <td class="text-primary pr-0 pt-7 text-left align-middle">
          {{ item.relevant_variants_cnt }}
        </td>
        <td
          class="text-primary pr-0 pt-7 text-right align-middle"
          v-if="type === 'curation'"
        >
          <a
            href="#"
            @click.prevent="
              $emit('togglePromoted', { id: item._id, add: true })
            "
            class="btn btn-icon btn-sm btn-hover-light-primary draggable-handle"
          >
            <i class="far fa-star"></i>
          </a>
          <a
            href="#"
            @click.prevent="$emit('toggleHidden', { id: item._id, add: true })"
            class="btn btn-icon btn-sm btn-hover-light-primary draggable-handle"
          >
            <i class="far fa-eye-slash"></i>
          </a>
        </td>
        <td
          class="text-primary pr-0 pt-7 text-right align-middle"
          v-if="type === 'none'"
        ></td>
      </tr>
      <tr v-bind:id="`row__${item._id}`" style="display:none">
        <td colspan="6">
          <table v-if="item !== null">
            <tr v-for="(value, key) in item" :key="key">
              <td>{{ key }}</td>
              <td>{{ value }}</td>
            </tr>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    type: {
      type: String,
      default: "none"
    }
  },
  methods: {
    clickRow(item) {
      var row = document.getElementById(`row__${item._id}`);

      if (row.style.display === "none") {
        row.style.display = "table-row";
      } else {
        row.style.display = "none";
      }
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    changeCollection() {
      return this.selectedCollection.id;
    },
    ...mapGetters(["selectedCollection", "currentProject"])
  }
};
</script>
